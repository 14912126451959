import React, {useEffect} from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

function Text({data}) {
  const [text, setText] = React.useState('');

  useEffect(() => {
    if (data.text === undefined) {
      fetch(data.link)
        .then(response => response.text())
        .then(data => {
          setText(data)
        });
    } else {
      setText(data.text);
    }
  }, [data]);

  if (text.trim() === '') {
    return <></>;
  } else {
    return (
      <ReactMarkdown remarkPlugins={[remarkGfm]}>
        {text}
      </ReactMarkdown>
    );
  }
}

export default Text;