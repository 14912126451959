import React, {useState, useEffect, useRef} from 'react';

import * as d3 from 'd3';

const apiName = 'choiceAPI';

function BarChart({data}) {
  const svgRef = useRef();
  const [dataset, setDataset] = useState([]);

  useEffect(() => {
    if (data.questionId) {
      fetch(
        'https://fgg3k9ho86.execute-api.us-east-1.amazonaws.com/staging/choice/' + data.questionId,
        {
          method: 'GET'
        }
      )
        .then(response => response.json())
        .then(ds => {
          let d = ds[0];
          delete d.questionId;
          const graphDataset = Object.entries(d)
            .map(([key, value]) => ({label: key, value: value}));
          setDataset(
            [
            {label: 'choice1', value: d.choice1},
            {label: 'choice2', value: d.choice2},
          ])
          setDataset(graphDataset);
        });
    }
  }, [data]);

  useEffect(() => {
    if (dataset.length > 0) {
      const svg = d3.select(svgRef.current);

      const xScale = d3.scaleBand()
        .domain(dataset.map(d => d.label))
        .range([data.margin.left, data.width - data.margin.right])
        .padding(0.5);

      const yScale = d3.scaleLinear()
        .domain([0, d3.max(dataset, d => d.value)])
        .range([data.height - data.margin.bottom, data.margin.top]);

      // Create a Title
      svg.selectAll('text.title')
        .data([data.title])
        .enter()
        .append('text')
        .attr('class', 'title')
        .attr('x', data.width / 2)
        .attr('y', data.margin.top / 2)
        .attr('text-anchor', 'middle')
        .style('font-size', '16px')
        .text("Count of People's Decision on each Choice");

      // Create and append axes
      const xAxis = d3.axisBottom(xScale);
      svg.select('.x-axis')
        .style('transform', `translateY(${data.height - data.margin.bottom}px)`)
        .call(xAxis);

      const yAxis = d3.axisLeft(yScale);
      svg.select('.y-axis')
        .style('transform', `translateX(${data.margin.left}px)`)
        .call(yAxis);

      // Create and append bars
      svg.selectAll('rect')
        .data(dataset)
        .enter()
        .append('rect')
        .attr('x', d => xScale(d.label))
        .attr('y', d => yScale(d.value))
        .attr('width', xScale.bandwidth())
        .attr('height', d => data.height - data.margin.bottom - yScale(d.value))
        .attr('fill', 'steelblue');
    }
  }, [dataset, data])

  return (
    <svg
      ref={svgRef}
      preserveAspectRatio={'xMinYMin meet'}
      viewBox={`0 0 ${data.width} ${data.height}`}
    >
      <g className="x-axis" />
      <g className="y-axis" />
    </svg>
  );
}

export default BarChart;
