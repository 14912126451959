import React, {useState} from 'react';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function Choice({data}) {
  const [selected, setSelected] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (choiceId) => {
    setSelected(choiceId);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!submitted && selected !== '') {
      fetch(
        'https://fgg3k9ho86.execute-api.us-east-1.amazonaws.com/staging/choice',
        {
          method: 'POST',
          headers: {
            "Content-type": "application/json; charset=UTF-8"
          },
          body: JSON.stringify({
            questionId: data.id,
            choice: selected,
          }),
        }
      )
        .then(response => {
          if (response.ok) {
            setSubmitted(true);
          }
        });
    }
  }

  return (
    <>
      <h3 className="mb-2">{data.question}</h3>
      <Form onSubmit={handleSubmit}>
        {data.choices.map((choice) => (
          <Form.Check
            key={choice.choiceId}
            type="radio"
            id={choice.choiceId}
            label={choice.text}
            checked={selected === choice.choiceId}
            onChange={() => handleChange(choice.choiceId)}
            />
        ))}
        <Button className="mt-2" type="submit">Submit</Button>
        {submitted && <p>Submitted!</p>}
      </Form>
    </>
  );
}

export default Choice;