import React  from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import Block from './Block';

function Page({data, onNext}) {
  return (
    <Container fluid>
      <Row className="justify-content-center mt-5">
        <Block data={data}/>
      </Row>
      <Row className="justify-content-center mt-2 mb-5">
        <Col xs='6'>
          <div className='float-end'>
            <Button
              variant="primary"
              onClick={() => onNext()}
            >
              Next
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Page;