import React, {useState, useEffect} from 'react';

import Alert from 'react-bootstrap/Alert';

import Page from './components/Page.js';

function App() {
  const [blocks, setBlocks] = useState([]);
  const [counter, setCounter] = useState(0);
  const [alert, setAlert] = useState(false);

  const getBlocks = () => {
    fetch(
      'data/blocks.json',
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(response => response.json())
      .then(data => {
        setBlocks(data)
      });
  }

  useEffect(() => {
    getBlocks();
  }, []);

  function onNext() {
    if (counter < blocks.length - 1) {
      setCounter(counter + 1);
    } else if (counter === blocks.length - 1 && !alert) {
      setAlert(true);
    } else {
      setCounter(0);
      setAlert(false);
    }
  }

  return (
    <>
      {alert &&
      <Alert>
        You have reached the end of the story. Press next again to restart.
      </Alert>}
      {blocks.length !== 0 && <Page data={blocks[counter]} onNext={onNext}/>}
    </>
  );
}

export default App;
