import React from 'react';

import Col from 'react-bootstrap/Col';

import BarChart from "./BarChart";
import Text from './Text';
import Choice from './Choice';

function Block({data}) {

  switch (data.type) {
    case 'Text':
      return (
        <Col className='text-left' xs='6'>
          <Text data={data.data}/>
        </Col>
      );
    case 'BarChart':
      return (
        <Col style={{maxWidth: '600px'}}>
          <BarChart data={data.data}/>
        </Col>
      );
    case 'Choice':
      return (
        <Col className='text-left' xs='6'>
          <Choice data={data.data}/>
        </Col>
      )
    default:
      return <div>Block type not found</div>;
  }
}

export default Block;